.view-actualites {
  .view-content {
    .row {
      display: flex;
      flex-flow: column wrap;

      @media (min-width: 768px) {
        flex-flow: row wrap;
        justify-content: space-between;

      }
      .views-row {
        width: 100%;
        padding: 0 15px;

        @media (min-width: 768px) {
          width: calc(50% - 2rem);
        }
      }
    }
  }
}
