// Form
.webform-client-form {
	margin-top: 45px;
	font-size: 0.94rem;
	font-weight: 100;
	line-height: 1.87em;
	strong {
		font-weight: 400;
	}
	p, ul, ol {
		margin: 0 0 25px;
	}
	.webform-submit {
		margin: 25px 15px;
		padding-left: 55px;
		padding-right: 55px;
		background-color: transparent;
    	border-color: @brand-primary;
    	color: @brand-primary;
    	&:hover {
    		background-color: @brand-primary;
    		border-color: @brand-primary;
    		color: #fff;
    	}
	}
}

.form-group {
    margin: 15px 0;
}
label {
    margin: 0 0 10px;
}
.form-control {
	margin: 0;
	padding: 0 25px;
	height: 50px;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.form-control, output, label {
	display: block;
	color: #777;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: .7px;
    line-height: normal;
    text-transform: uppercase;
}
.form-required {
	color: #ff3b3b;
}
textarea {
	padding: 15px 25px !important;
}


.aside-contact {
	margin-top: 45px;
	padding-left: 30px;
	@media (max-width: @screen-xs-max) {
		padding-left: 0;
	}
	.small {
		margin-bottom: 40px;
		font-size: 0.94rem;
		font-weight: 100;
		line-height: 1.87em;
	}
}

.contacts {
	font-size: 0;

	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	.icon {
		display: inline-block;
		vertical-align: top;
		width: 58px;
		@media (max-width: @screen-xs-max) {
			margin-bottom: 15px;
		}
	}
	.contact {
		display: inline-block;
		vertical-align: top;
		padding-left: 20px;
		width: calc(~"100% - 58px");
		font-size: 1rem;
		@media (max-width: @screen-xs-max) {
			padding-left: 0;
			width: 100%;
		}
		.item {
			& + .item {
				margin-top: 20px;
			}
		}
	}
	& + .contacts {
		margin-top: 40px;
	}
}