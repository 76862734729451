@media(min-width: @screen-md) {

  .megamenu {
    position: static !important;
    > ul {
      width: 100%;
      background-color: red;
      opacity: 0;
      pointer-events: none;
      display: flex;
      flex-flow: row wrap;
      position: relative;
      transition: .3s ease-out;

      > li {
        width: 33.3333333%;
      }

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        background: #fff;
        height: 100%;
        width: 100%;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        right: -99%;
        background: #fff;
        height: 100%;
        width: 100%;
      }
    }

    &.open {

      > ul {
        display: flex;
        flex-flow: row wrap;
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
