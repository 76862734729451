// Solutions
#intro-solution {
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
  color: #fff;
  overflow: hidden;
  .wrapper {
    background-color: #f5f5f5;
  }
  .container {
    position: relative;
    padding: 40px 60px;
    @media (max-width: @screen-md-max) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @media (max-width: @screen-sm-max) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (max-width: @screen-xs-max) {
      padding-left: 15px;
      padding-right: 15px;
      background-color: @brand-primary;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      width: calc(~"100vw - (100vw - 1170px) / 2");
      background-color: @brand-primary;
      box-shadow: 5px -1px 18px 0px rgba(0, 0, 0, 0.21);
      @media (max-width: @screen-md-max) {
        width: calc(~"100vw - (100vw - 970px) / 2");
      }
      @media (max-width: @screen-sm-max) {
        width: calc(~"100vw - (100vw - 750px) / 2");
      }
      @media (max-width: @screen-xs-max) {
        display: none;
      }
    }
  }
  .content-txt {
    position: relative;
    z-index: 1;
  }
  h2 {
    margin: 0;
    color: #fff;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.67em;
    text-transform: uppercase;
    @media (max-width: @screen-xs-max) {
      font-size: 1.88rem;
    }
  }
}

#tv,
#fonctionnement,
#benefices,
#specialites {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      &:before {
        content: "\f105";
        margin-right: 5px;
        font-family: "FontAwesome";
      }
    }
  }
}

#tv {
  margin-top: -10px;
  margin-bottom: -225px;
  background-color: #f5f5f5;
  overflow: hidden;
  @media (max-width: @screen-xs-max) {
    margin-bottom: 0;
  }

  .row {
    display: flex;
    flex-flow: column wrap;
    padding: 0 15px;
    @media (min-width: @screen-sm) {
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .item--tv {
      @media (min-width: @screen-sm) {
        width: calc(50%);
      }
      .img-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        img {
          max-width: 360px;
        }
      }

      .content {
        min-height: 0;
        ul {
          max-width: 285px;
        }
        p {
          max-width: 285px;
          text-align: left;
        }
      }
    }
  }
  .container {
    position: relative;
    padding-top: 65px;
    background-color: #fff;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 9999px;
      background-color: #fff;
    }
  }
  ul {
    // float: right;
    // max-width: 285px;
    text-align: left;
  }
}

#fonctionnement {
  padding-top: 355px;
  padding-bottom: 135px;
  background-color: @brand-primary;
  color: #fff;
  font-size: 1.06rem;
  font-weight: 300;
  line-height: 1.88em;
  text-align: right;
  @media (max-width: @screen-sm-max) {
    text-align: left;
  }
  @media (max-width: @screen-xs-max) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title {
    position: relative;
    margin: 0 0 25px;
    padding: 30px 0 0;
    color: #fff;
    font-size: 2.19rem;
    font-weight: 600;
    line-height: 1.49em;
    text-transform: uppercase;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 1px;
      width: 110px;
      background-color: #fff;
      @media (max-width: @screen-sm-max) {
        left: 0;
        right: auto;
      }
    }
  }
  p,
  ul,
  ol {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul,
  ol {
    li {
      & + li {
        margin-top: 20px;
      }
    }
  }
  .img-wrapper {
    text-align: center;
    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
    }
    img {
      display: inline-block;
    }
  }
}

#benefices {
  padding: 20px 0;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.8em;
  h2 {
    margin: 0;
    color: #fff;
    font-size: 2.19rem;
    font-weight: 600;
    line-height: 1.49em;
    text-align: right;
    text-transform: uppercase;
    @media (max-width: @screen-sm-max) {
      text-align: left;
    }
  }
  strong {
    font-weight: 500;
  }
  iframe {
    @media (max-width: @screen-xs-max) {
      max-width: 100%;
    }
  }
}

#specialites {
  position: relative;
  margin-top: 110px;
  @media (max-width: @screen-xs-max) {
    margin-top: 0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(~"100vw - (100vw - 1140px) / 2");
    background-color: #fff;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
    @media (max-width: @screen-md-max) {
      width: calc(~"100vw - (100vw - 940px) / 2");
    }
    @media (max-width: @screen-sm-max) {
      width: calc(~"100vw - (100vw - 720px) / 2");
    }
    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }
  .content {
    position: relative;
    z-index: 5;
    color: #222;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.07em;
    .wrapper {
      padding: 60px 65px 50px;
      background-color: #fff;
      @media (max-width: @screen-md-max) {
        padding-left: 45px;
        padding-right: 45px;
      }
      @media (max-width: @screen-sm-max) {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media (max-width: @screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    h2 {
      margin: 0 0 55px;
      color: @brand-primary;
      font-size: 3.13rem;
      font-weight: 700;
      line-height: 1.16em;
      text-transform: uppercase;
      @media (max-width: @screen-sm-max) {
        font-size: 2.25rem;
      }
    }
    ul {
      @media (min-width: @screen-sm-min) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
      li {
        display: inline-block;
        margin: 0 0 30px;
        width: 100%;
      }
    }
    a {
      color: #222;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .bg {
    z-index: 1;
    right: 60%;
    @media (max-width: @screen-xs-max) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      padding-bottom: 56.25%;
    }
  }
}

#video {
  padding-top: 80px;
  .iframe-wrapper {
    padding: 2.38% 1.38% 3.49%;
    background-image: url(../img/tv.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .embed-iframe {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &::before {
      display: block;
      content: "";
      padding-top: 56.25%;
    }
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  p {
    margin: 15px 0 0;
    color: #000;
    font-family: @ff-title;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.21em;
    @media (min-width: @screen-sm-min) {
      position: absolute;
      left: 15px;
      bottom: 0;
    }
  }
  &.Portrait {
    .iframe-wrapper {
      padding: 1.38% 2.38% 1.83%;
      background-image: url(../img/tv-portrait.png);
    }
    .embed-iframe {
      &::before {
        padding-top: 177.78%;
      }
    }
  }
}

#confiance {
  padding-top: 80px;
  color: #000;
  font-family: @ff-title;
  font-size: 1.25rem;
  font-weight: 300;
  .container {
    padding-top: 45px;
    padding-bottom: 130px;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 136px 0px rgba(0, 0, 0, 0.19);
    @media (max-width: @screen-xs-max) {
      padding-bottom: 60px;
    }
  }
  .wrapper {
    padding: 0 90px;
    @media (max-width: @screen-md-max) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @media (max-width: @screen-sm-max) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (max-width: @screen-xs-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  h2 {
    margin: 0 0 45px;
    color: #000;
    font-size: 2.19rem;
    font-weight: 600;
    line-height: 1.49em;
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @media (min-width: @screen-sm-min) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    li {
      display: inline-block;
      margin: 0 0 20px;
      width: 100%;
    }
  }
  strong {
    font-weight: 400;
  }
}

#cta-solution {
  padding-top: 95px;
  padding-bottom: 95px;
  text-align: center;
  .btn {
    padding-left: 20px;
    padding-right: 50px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.25em;
    &:before {
      left: calc(~"100% - 30px");
    }
  }
}
