// Produits
.page-catalogue-produits {
	.product-menu {
		padding: 0;
		background-color: @brand-secondary;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		> ul {
			> li {
				> a {
					display: block;
					padding: 10px 15px;
					background-color: #222;
					color: #fff;
					font-size: 1.13rem;
					text-decoration: none !important;
					.transition(all .2s);
					&:hover, &.active {
						background-color: @brand-primary;
					}
				}
				& + li {
					border-top: 1px solid rgba(255,255,255,.2);
				}
				> ul {
					padding: 10px 0;
					> li {
						> a {
							display: block;
							padding: 5px 30px;
							color: #fff;
							text-decoration: none !important;
							.transition(all .2s);
							&:hover, &.active {
								color: @brand-primary;
							}
						}
						> ul {
							> li {
								> a {
									display: block;
									padding: 0 45px;
									color: #fff;
									font-style: italic;
									text-decoration: none !important;
									.transition(all .2s);
									&:hover, &.active {
										color: @brand-primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.product-list {
		padding-top: 65px;
		padding-bottom: 170px;

		.wrapper__products {
			display: flex;
			flex-flow: row wrap;

		}
	}
	.cat-wrapper {
    @media (min-width: 992px) {
      height: 600px;
      overflow: hidden;
    }
    @media (min-width: 1200px) {
      height: 570px;
    }
		@media (min-width: 1500px) {
			width: 25%;
		}
	}
	.produit-list-wrapper {
		@media (min-width: 1600px) {
			width: 25%;
		}
	}
}

.back-productlist {
	background-color: @brand-primary;
	text-align: right;
	a {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #181818;
		font-family: @ff-title;
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 1.8em;
		text-transform: uppercase;
	}
}

#produit-details {
	padding-top: 60px;
	padding-bottom: 80px;
	font-size: 0.94rem;
	line-height: 1.73em;
	h2 {
		margin: 0 0 25px;
		font-size: 2rem;
	}
	h3 {
		margin: 0 0 15px;
		font-size: 1.5rem;
	}
	h4 {
		margin: 0 0 10px;
		font-size: 1.19rem;
		font-weight: 400;
		text-transform: none;
	}
	ul:not(.specs-list) {
		padding: 0;
		list-style: none;
		li {
			position: relative;
			padding-left: 15px;
			&:before {
				content: '-';
				position: absolute;
				top: 0;
				left: 5px;
			}
		}
	}
	p, ul:not(.specs-list), ol {
		// margin: 0 0 45px;
		margin: 0 0 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.row {
		@media (max-width: @screen-sm-max) {
			margin-bottom: 45px;
			&:last-child {
				margin-bottom: 0;
			}
			.col-md-6 {
				&:first-child {
					margin-bottom: 45px;
				}
			}
		}
	}
	.gallery {
		@media (max-width: @screen-sm-max) {
			margin-top: 60px;
		}
	}
	.img-wrapper {
		position: relative;
		float: left;
		background-color: #f9f9f9;
		border: 1px solid #c1c1c1;
		height: 450px;
		width: 100%;
		@media (max-width: @screen-xs-max) {
			height: 300px;
		}
		img {
	    	position: absolute;
		    top: 50%;
		    left: 50%;
		    -webkit-transform: translate(-50%,-50%);
		    -moz-transform: translate(-50%,-50%);
		    -ms-transform: translate(-50%,-50%);
		    -o-transform: translate(-50%,-50%);
		    transform: translate(-50%,-50%);
	    	height: auto;
		    max-height: 100%;
		    width: auto;
		    max-width: 100%;
	    }
	    & + .img-wrapper {
	    	margin-top: 30px;
	    }
	    &:nth-child(3n+2) {
	    	margin-right: 15px;
	    	height: 300px;
	    	width: calc(~"50% - 15px");
	    	@media (max-width: @screen-xs-max) {
	    		margin-right: 0;
				width: 100%;
			}
	    }
	    &:nth-child(3n+3) {
	    	margin-left: 15px;
	    	height: 300px;
	    	width: calc(~"50% - 15px");
	    	@media (max-width: @screen-xs-max) {
	    		margin-left: 0;
				width: 100%;
			}
	    }
	}

	.specs {
		margin-top: 85px;
		ul {
			margin: 50px 0 0;
			padding: 0;
			list-style: none;
			li {
				font-size: 0;
				h3, span {
					display: inline-block;
					vertical-align: middle;
					margin: 0;
					padding: 10px;
					width: 50%;
					color: #777;
					font-size: 1rem;
					line-height: 1em;
					text-transform: none;
					@media (max-width: @screen-xs-max) {
						display: block;
						width: 100%;
					}
				}
				h3 {
					color: #333;
					font-weight: 500;
				}
				& + li {
					border-top: 1px solid #e5e5e5;
				}
			}
		}
	}
}
