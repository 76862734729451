#main.container {
  position: relative;
  margin-top: -300px;
  padding: 50px;
  background-color: #fff;
  @media (max-width: @screen-xs-max) {
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 45px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.v-align {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.no-gap {
  padding-left: 0;
  padding-right: 0;
}
.no-marge {
  margin-left: 0;
  margin-right: 0;
}
.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.same-height--sm {
  @media (min-width: @screen-sm-min) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

// Animate
.animated {
  .animated;
}
.os-animation {
  opacity: 0;
  @media (max-width: @screen-xs-max) {
    -webkit-animation-delay: 0s !important;
    -moz-animation-delay: 0s !important;
    -ms-animation-delay: 0s !important;
    -o-animation-delay: 0s !important;
    animation-delay: 0s !important;
  }
}

// Types
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
  letter-spacing: -0.4px;
  line-height: 1.24em;
  text-transform: uppercase;
  span {
    color: @brand-primary;
  }
  @media (max-width: @screen-xs-max) {
    font-size: 1.88rem;
  }
}
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #232323;
  line-height: 1.19em;
  text-transform: uppercase;
}
h3,
.h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #222222;
  line-height: 1.31em;
  text-transform: uppercase;
}
h4,
.h4 {
  margin-top: 0;
  margin-bottom: 25px;
  color: #222222;
  text-transform: uppercase;
}
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #222222;
  font-weight: 400;
  line-height: 1.33em;
}

// Lead
p.lead {
  font-size: 1.33em;
  line-height: 1.5em;
  color: @brand-primary;
  font-weight: 300;
  margin-bottom: @line-height-computed;
}

// Evidence
p.evidence,
div.evidence {
  color: #fff;
  background-color: @brand-primary;
  padding: @line-height-computed*2;
  margin-bottom: @line-height-computed;
  @media (max-width: @screen-sm-max) {
    padding: 30px;
  }
  @media (max-width: @screen-xs-max) {
    padding: 15px;
  }

  a {
    color: #fff;
  }

  a.btn {
    background-color: #fff;
    color: @brand-primary;

    &:hover {
      background-color: darken(#fff, 10%);
    }
  }
}

// Img
img {
  max-width: 100%;
  height: auto;

  &[style*="left"] {
    margin-right: @line-height-computed;
    margin-bottom: @line-height-computed;
  }

  &[style*="right"] {
    margin-left: @line-height-computed;
    margin-bottom: @line-height-computed;
  }
}

// Table
table {
  border: 1px solid #d8d8d8;
  margin-bottom: @line-height-computed;
  width: 100%;

  th {
    background-color: @brand-primary;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
  }

  td {
    background-color: #f0f0f0;
  }

  tr.even td {
    background-color: darken(#f0f0f0, 5%);
  }

  td,
  th {
    padding: @line-height-computed / 2 @line-height-computed;
  }
}

// Iframe responsive
.iframe-embed {
  display: block;
  position: relative;
  margin-bottom: 20px;
  padding: 0;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  box-shadow: 0px 1px 0px 0px rgba(208, 208, 208, 1);
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// Boutons
.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 10px 30px;
  border: 2px solid transparent;
  border-radius: 0;
  font-size: 0.81rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 2em;
  text-align: center;
  text-transform: uppercase;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  cursor: pointer;
  outline: 0 !important;
  white-space: normal;
  .transition(all 0.2s);
  &.btn-primary,
  &.btn-default,
  &.btn1 {
    background-color: @brand-primary;
    border-color: @brand-primary;
    color: #fff;
    &:hover {
      background-color: @brand-secondary;
      border-color: @brand-secondary;
    }
  }
  &.btn2,
  &.btn-border--primary {
    background-color: transparent;
    border-color: @brand-primary;
    color: @brand-primary;
    &:hover {
      background-color: @brand-primary;
      border-color: @brand-primary;
      color: #fff;
    }
  }
  &.btn-border--white {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: @brand-primary;
    }
  }
}
.lined--primary {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(~"100% - 15px");
    height: 1px;
    width: 70px;
    background-color: @brand-primary;
    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }
}

.custom-list {
  padding: 0;
  list-style: none;
  li {
    &:before {
      content: "\f105";
      margin-right: 5px;
      font-family: "FontAwesome";
    }
  }
}

.more {
  color: #000;
  font-family: @ff-title;
  font-size: 1.25rem;
  line-height: 1.5em;
  text-decoration: underline;
  .fa {
    margin-left: 10px;
  }
  &:hover {
    text-decoration: none;
  }
}

.item {
  &--news {
    margin: 15px 0;
    h3 {
      margin: 30px 0 5px;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
    p {
      margin: 0;
      color: #c1c1c1;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
    .btn {
      margin-top: 30px;
      padding: 10px 40px;
    }
    &_page {
      h3 {
        color: #29282e;
      }
    }
  }
  &--produit {
    display: block;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: 0.5;
      .transition(all 0.2s);
    }
    .img-wrapper {
      position: relative;
      height: 100%;
      min-height: 320px;
      @media (max-width: @screen-sm-max) {
        height: 320px !important;
        min-height: 0;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
    .content {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      padding: 20px 60px;
      width: 100%;
      @media (max-width: @screen-md-max) {
        padding-left: 30px;
        padding-right: 30px;
      }
      .type {
        display: inline-block;
        padding: 5px 14px;
        background-color: @brand-primary;
        color: #fff;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: normal;
        text-transform: uppercase;
      }
      h3 {
        margin: 15px 0 0;
        color: #fff;
        font-size: 1.25rem;
        line-height: normal;
      }
    }
    &:hover {
      &:before {
        opacity: 0.9;
      }
    }
  }
  &--services {
    position: relative;
    z-index: 5;
    @media (max-width: @screen-xs-max) {
      background-color: #fff;
    }
    .content {
      position: relative;
      z-index: 10;
      left: 25%;
      padding: 100px 75px 100px 170px;
      // min-height: 700px;
      width: 75%;
      background-color: #fff;
      color: #222;
      // font-size: 1.38rem;
      font-size: 1.13rem;
      font-weight: 300;
      line-height: 1.36em;
      box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
      @media (max-width: @screen-sm-max) {
        left: auto;
        padding: 60px 75px;
        min-height: 0;
        width: 100%;
      }
      @media (max-width: @screen-xs-max) {
        padding: 60px 0;
        box-shadow: none;
      }
      h2 {
        font-size: 1.88rem;
      }
      h3 {
        font-size: 1.5rem;
      }
      strong {
        font-weight: 400;
      }
      ul {
        .custom-list;
      }
      ul,
      ol {
        li + li {
          margin-top: 15px;
        }
      }
      p,
      ul,
      ol {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .img-wrapper {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 60%;
      bottom: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: @screen-sm-max) {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        padding-bottom: 56.25%;
      }
      @media (max-width: @screen-xs-max) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      margin-left: -30px;
      max-width: 540px;
      color: @brand-primary;
      @media (max-width: @screen-xs-max) {
        margin-left: 0;
        font-size: 1.88rem;
      }
    }
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.34em;
      @media (max-width: @screen-xs-max) {
        font-size: 1.5rem;
      }
      span {
        display: block;
        font-weight: 500;
        text-transform: none;
      }
    }
    &:nth-child(even) {
      z-index: 0;
      margin-top: -45px;
      margin-bottom: -60px;
      @media (max-width: @screen-sm-max) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .content {
        left: auto;
      }
      .img-wrapper {
        left: 60%;
        right: 0;
        @media (max-width: @screen-sm-max) {
          left: auto;
          right: auto;
        }
      }
    }
    &:last-child {
      margin-bottom: -100px;
    }
  }
  &--cat {
    display: block;
    margin: 15px 0;
    text-decoration: none !important;
    .img-wrapper {
      position: relative;
      padding-bottom: 100%;
      height: 0;
      background-color: #fff;
      box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.08);
      .wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .transition(all 0.2s);
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: auto;
        max-height: calc(~"100% - 30px");
        width: auto;
        max-width: calc(~"100% - 30px");
      }
    }
    .content {
      padding: 20px 0;
      min-height: 215px;
      color: #222;
      font-size: 0.94rem;
      font-weight: 300;
      line-height: 1.87em;
      &:before {
        content: "";
        display: block;
        height: 1px;
        width: 100px;
        background-color: #000;
      }
      h3 {
        margin: 10px 0 20px;
        color: #222;
        font-family: @ff-body;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.4em;
        text-transform: uppercase;
      }
      strong {
        font-weight: 500;
      }
    }
    &:hover {
      .img-wrapper {
        .wrap {
          box-shadow: 12px 12px 0px 0px rgba(255, 166, 10, 1);
        }
      }
    }
  }
  &--produit-list {
    display: block;
    margin: 15px 0;
    border: 1px solid #e6e6e6;
    color: #939393;
    font-size: 1.06rem;
    font-weight: 300;
    line-height: 1.44em;
    text-decoration: none !important;
    .transition(all 0.2s);
    .img-wrapper {
      position: relative;
      height: 250px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
    .content {
      padding: 25px 30px 30px;
      min-height: 270px;
    }
    h3 {
      margin: 0 0 10px;
      color: #222;
      font-family: @ff-body;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.3em;
      text-transform: uppercase;
    }
    &:hover {
      color: @brand-primary;
    }
  }
  &--tv {
    margin: 15px 0;
    .img-wrapper {
      img {
        width: 100%;
      }
    }
    .content {
      .clearfix;
      padding: 10px 0;
      min-height: 180px;
      color: #777;
      font-size: 1.06rem;
      line-height: 1.88em;
      text-align: right;
      @media (max-width: @screen-xs-max) {
        min-height: 0;
      }
    }
  }
  &--ref {
    display: block;
    position: relative;
    margin: 15px 0;
    background-color: #000;
    .img-wrapper {
      position: relative;
      height: 270px;
      width: 100%;
      overflow: hidden;
      opacity: 0.5;
      .transition(all 0.2s);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .content {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      padding: 20px 15px;
      h3 {
        margin: 0;
        color: #fff;
        font-size: 1.13rem;
      }
    }
    &:hover {
      .img-wrapper {
        opacity: 0.1;
      }
    }
  }
}

.node-details {
  .img-wrapper {
    img {
      float: left;
      margin: 0 30px 30px 0;
      @media (max-width: @screen-xs-max) {
        float: none;
        margin: 0 0 30px;
      }
    }
  }
}

#swipebox-slider .slide-loading {
  background: url(../img/loader.gif) no-repeat center center;
}
#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(../img/icons.png);
}

//Paragraphs
.content-texte,
.content-evidence {
  p,
  ul,
  ol {
    margin: 0 0 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.content-texte {
  padding-top: 60px;
  padding-bottom: 60px;
  .row {
    margin-bottom: 35px;
    @media (max-width: @screen-sm-max) {
      .col-md-6 {
        &:first-child {
          margin-bottom: 25px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.content-evidence {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: @brand-primary;
  color: #fff;
  text-align: center;
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
  .btn {
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: @screen-xs-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.custom-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  -o-transform: translate(0, 100%);
  transform: translate(0, 100%);
  .transition(all 0.35s);
  &.open {
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .overlay-close {
    position: fixed;
    z-index: 100;
    top: 30px;
    right: 30px;
    color: #fff;
    font-size: 32px;
    line-height: 1em;
    text-decoration: none !important;
  }
  .banner {
    height: 480px;
    @media (max-width: @screen-xs-max) {
      height: 330px;
    }
    .bg {
      opacity: 0.5;
    }
  }
}

.node-type-references {
  #banner {
    height: 550px;
  }
  .ref-content {
    padding-bottom: 60px;
    @media (max-width: @screen-xs-max) {
      padding-top: 0px;
    }
    .row {
      position: relative;
      .back {
        position: absolute;
        top: 1rem;
        right: 15px;
        z-index: 2;
      }
    }
    .info {
      .logo {
        position: relative;
        margin-top: -120px;
        margin-bottom: 30px;
        height: 0;
        padding-bottom: 100%;
        background-color: #fff;
        @media (max-width: @screen-xs-max) {
          margin-top: 50px;
        }
        .wrapper {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 15px;
        }
        .inner-wrapper {
          position: relative;
          height: 100%;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            height: auto;
            max-height: 100%;
            width: auto;
            max-width: 100%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
        }
      }
      h4 {
        margin: 0 0 10px;
        font-size: 1.5rem;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          a {
            display: block;
            color: #222;
            font-family: @ff-title;
            font-size: 1.13rem;
            font-weight: 400;
            line-height: 1.5em;
            text-decoration: underline;
            .transition(all 0.2s);
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .description {
      padding-top: 80px;
      padding-bottom: 30px;
      .owl-carousel {
        .clearfix;
        display: block;
        margin-bottom: 30px;
        > .item {
          float: left;
          padding: 15px;
          width: 33.33333333%;
        }
        .owl-nav {
          margin-top: 15px;
          font-size: 0;
          text-align: right;
        }
        .owl-prev,
        .owl-next {
          display: inline-block;
          vertical-align: middle;
          color: @brand-secondary;
          font-size: 2.25rem;
          line-height: 1em;
          .transition(all 0.35s);
          &:hover {
            color: @brand-primary;
          }
        }
        .owl-next {
          margin-left: 15px;
        }
      }
    }
  }

  .row {
    position: relative;
  }
}

body.open-overlay {
  overflow: hidden;
}

.secteur-desc {
  .img-wrapper {
    margin-bottom: 30px;
  }
}
.secteur-solutions {
  padding-top: 60px;
  color: #000;
  font-size: 1.13rem;
  h2 {
    margin: 0 0 20px;
    color: @brand-primary;
  }
  ul {
    .custom-list;
    @media (min-width: @screen-md-min) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    li {
      display: inline-block;
      margin: 0 0 20px;
      width: 100%;
      &:before {
        font-size: 1.25rem;
      }
      span {
        color: #777;
        font-size: 1rem;
        font-weight: 100;
      }
      a {
        color: #232323;
        font-family: @ff-title;
        text-decoration: none;
        .transition(all 0.2s);
        &:hover {
          color: @brand-primary;
        }
      }
    }
  }
}

.search-results {
  .title {
    font-size: 1.5rem;
  }
}

#map {
  height: 580px;
  z-index: 0;
}

.contact-text {
  *:first-child {
    margin-top: 45px;
  }
}
.page-widgets {
  #banner {
    height: 440px;
    h1 {
      text-align: left;
    }
  }
  #main.container {
    margin-top: 0;
    padding-top: 0;
  }
}
.view-widgets {
  .view-content {
    .views-row {
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
  }
}
.widget-item {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  @media (max-width: @screen-md-min) {
    justify-content: center;
  }
  h2 {
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .widget-picto {
    width: 120px;
    img {
      max-width: 100%;
    }
    @media (max-width: @screen-md-min) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .widget-content {
    width: calc(100% - 320px);
    @media (max-width: @screen-md-min) {
      width: 100%;
    }
  }
  .widget-image {
    width: 200px;
    @media (max-width: @screen-md-min) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    img {
      max-width: 100%;
    }
  }
}
