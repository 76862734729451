// Footer
#footer {
	.footer-top {
		padding-top: 90px;
		padding-bottom: 75px;
		background-color: #15161d;
		@media(max-width: @screen-xs-max) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.col-md-3 {
			margin: 15px 0;
			padding-top: 60px;
			color: #fff;
			font-size: 1rem;
			line-height: 1.63em;
			text-align: center;
			background-position: center top;
			background-repeat: no-repeat;

			/* These are technically the same, but use both */
			overflow-wrap: break-word;
			word-wrap: break-word;

			-ms-word-break: break-all;
			/* This is the dangerous one in WebKit, as it breaks things wherever */
			word-break: break-all;
			/* Instead use this non-standard one: */
			word-break: break-word;

			/* Adds a hyphen where the word breaks, if supported (No Blink) */
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			-webkit-hyphens: auto;
			hyphens: auto;
			a {
				color: #fff;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			&:nth-child(1) {
				background-image: url(../img/icon-location.png);
			}
			&:nth-child(2) {
				background-image: url(../img/icon-tel.png);
			}
			&:nth-child(3) {
				background-image: url(../img/icon-mail.png);
			}
			&:nth-child(4) {
				background-image: url(../img/icon-hour.png);
			}
		}
	}
	.footer-bottom {
		padding-top: 25px;
		padding-bottom: 25px;
		background-color: #0c0c0c;
		color: #fff;
		font-size: 0.81rem;
		letter-spacing: .4px;
		line-height: normal;
		text-transform: uppercase;
		a {
			color: #fff;
			font-size: 0.75rem;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			& + a {
				margin-left: 40px;
				@media(max-width: @screen-sm-max) {
					margin-left: 20px;
				}
				@media(max-width: @screen-xs-max) {
					display: block;
					margin-top: 10px;
					margin-left: 0;
				}
			}
		}
		div[class*="col-"] {
			&:last-child {
				text-align: right;
				@media(max-width: @screen-sm-max) {
					margin-top: 15px;
					text-align: left;
				}
			}
		}
	}
}