// Services
#intro-services {
	// padding-top: 160px;
	// padding-bottom: 150px;
	// font-size: 1.06rem;
	padding-top: 90px;
	padding-bottom: 90px;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: -.2px;
	line-height: 1.88em;
	@media (max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	/* .container {
		position: relative;
		left: 180px;
		@media(max-width: 1529px) {
			left: 0;
		}
	} */
	strong {
		font-weight: 400;
	}
	p, ul, ol {
		margin: 0;
	}
	ul {
		.custom-list;
	}
}

#highlight-services {
	overflow-x: hidden;
	.wrapper {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		padding: 25px 30px;
		max-width: 1415px;
		background-color: #e6e6e6;
		color: #000;
		// font-size: 2.19rem;
		font-size: 1.63rem;
		font-weight: 400;
		line-height: 1.06em;
		text-align: center;
		@media (max-width: @screen-xs-max) {
			font-size: 1.88rem;
		}
		strong {
			font-weight: 600;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 100%;
			width: 9999px;
			background-color: #e6e6e6;
		}
	}
}

#menu-services {
	margin-top: -30px;
	padding-top: 45px;
	padding-bottom: 20px;
	background-color: @brand-primary;
	.wrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 1515px;
		font-size: 0;
	}
	a {
		display: inline-block;
		vertical-align: top;
		padding: 15px 50px;
		min-height: 74px;
		width: 25%;
		background-image: url(../img/arrow.png);
		background-position: right top;
		background-repeat: no-repeat;
		background-size: auto 100%;
		color: #fff;
		// font-size: 2.5rem;
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: -.2px;
		line-height: 1.13em;
		text-decoration: underline;
		@media(max-width: 1439px) {
			padding-left: 25px;
			padding-right: 25px;
		}
		@media(max-width: @screen-md-max) {
			width: 33.33333333%;
		}
		@media (max-width: @screen-sm-max) {
			width: 50%;
		}
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
		&:hover {
			text-decoration: none;
		}
		&:last-child {
			background-image: none;
		}
	}
}

#services {
	padding-top: 150px;
	padding-bottom: 1px;
	background-color: #f5f5f5;
	@media (max-width: @screen-xs-max) {
		padding-top: 60px;
	}
}

#contact-services {
	padding-top: 200px;
	padding-bottom: 80px;
	text-align: center;
	.btn {
		padding: 12px 35px;
		font-size: 1rem;
		font-weight: 700;
	}
}