// Header
#header {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  .transition(all 0.2s);
  .logo {
    float: left;
    padding: 29px 10px;
    .transition(all 0.2s);
    @media (max-width: @screen-md-max) {
      padding-top: 33px;
      padding-bottom: 33px;
    }
    @media (max-width: @screen-xs-max) {
      padding-top: 18px;
      padding-bottom: 18px;
    }
    svg {
      display: block;
      @media (max-width: @screen-md-max) {
        height: auto;
        width: 190px;
      }
    }
  }
  .top {
    .container {
      .transition(all 0.2s);
      @media (max-width: @screen-xs-max) {
        background-color: @brand-secondary;
      }
    }
    .wrapper {
      float: right;
    }
  }
  .main {
    position: relative;
    .socials {
      position: absolute;
      top: -40px;
      right: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;

      @media (max-width: @screen-sm-max) {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        margin-right: 15px;
      }

      a {
        display: inherit;
        text-decoration: none;

        i {
          color: #fff;
          transition: 0.3s ease-out;
        }

        &:hover {
          i {
            color: @brand-primary;
          }
        }
      }
    }
    .container {
      position: relative;
      background-color: #fff;
    }
  }
  .mainNav {
    float: right;
    .transition(all 0.2s);
    @media (max-width: @screen-sm-max) {
      float: none;
    }
    > ul {
      float: left;
      margin: 0 20px;
      padding: 0;
      list-style: none;
      @media (max-width: @screen-md-max) {
        margin-left: 15px;
        margin-right: 15px;
      }
      @media (max-width: @screen-sm-max) {
        float: none;
        margin: 0;
      }
      > li {
        float: left;
        padding: 35px 0;
        .transition(all 0.2s);
        @media (max-width: @screen-sm-max) {
          float: none;
          padding: 0;
        }
        > a,
        > .nolink {
          display: block;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border-bottom: 2px solid transparent;
          color: #000;
          font-size: 0.94rem;
          font-weight: 500;
          line-height: 1.87em;
          text-transform: uppercase;
          .transition(all 0.2s);
          .caret {
            display: none;
          }
          @media (max-width: @screen-sm-max) {
            padding: 15px;
          }
          &:hover {
            border-color: @brand-primary;
            color: @brand-primary;
            @media (max-width: @screen-sm-max) {
              border-color: #fff;
              color: #fff;
            }
          }
        }
        &.active,
        &.active-trail {
          > a,
          > .nolink {
            border-color: @brand-primary;
            color: @brand-primary;
            @media (max-width: @screen-sm-max) {
              border-color: #fff;
              color: #fff;
            }
          }
        }
        &.open {
          @media (min-width: @screen-md-min) {
            padding-bottom: 33px;
            border-bottom: 2px solid #000;
          }
        }
        & + li {
          margin-left: 40px;
          @media (max-width: @screen-md-max) {
            margin-left: 30px;
          }
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
        > ul.dropdown-menu {
          position: absolute;
          z-index: 1000;
          top: calc(100% - 2px);
          left: 0;
          float: none;
          margin: 2px 0 0;
          padding: 20px 0;
          min-width: 300px;
          list-style: none;
          background-color: #fff;
          border: 0;
          border-top: 2px solid #e5e5e5;
          border-radius: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          @media (max-width: @screen-sm-max) {
            position: relative;
            top: auto;
            left: auto;
            margin: 0;
            background-color: fade(#fff, 90%);
            text-align: center;
          }
          > li {
            padding: 5px 25px;
            > a,
            > .nolink {
              margin: 0;
              padding: 0;
              background-color: transparent;
              color: #6c6a6b;
              font-family: @headings-font-family;
              font-size: 1rem;
              font-weight: 700;
              line-height: 1.25em;
              white-space: normal;
              .transition(all 0.2s);
              .caret {
                display: none;
              }
              @media (max-width: @screen-xs-max) {
                padding: 15px 15px;
              }
              &:hover {
                color: @brand-primary;
              }
            }
            &.active,
            &.active-trail {
              > a,
              > .nolink {
                color: @brand-primary;
              }
            }
            > ul.dropdown-menu {
              display: block;
              position: relative;
              top: auto;
              left: auto;
              float: none;
              margin: 5px 0 0;
              padding: 0;
              min-width: 0;
              list-style: none;
              background-color: transparent;
              border: 0;
              border-radius: 0;
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
              @media (max-width: @screen-sm-max) {
                text-align: center;
              }
              > li {
                > a,
                > .nolink {
                  margin: 0;
                  padding: 4px 0;
                  background-color: transparent;
                  color: #9a9a9a;
                  font-family: @headings-font-family;
                  font-size: 0.88rem;
                  font-weight: 400;
                  line-height: 1.43em;
                  white-space: normal;
                  .transition(all 0.2s);
                  @media (max-width: @screen-xs-max) {
                    padding: 10px 15px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }
                &.plus {
                  > a,
                  > .nolink {
                    color: @brand-primary;
                    text-decoration: underline;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
                &.active,
                &.active-trail {
                  > a,
                  > .nolink {
                    text-decoration: underline;
                  }
                  &.plus {
                    > a,
                    > .nolink {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
        &.mm {
          position: static;
          @media (max-width: @screen-sm-max) {
            position: relative;
          }
          > ul.dropdown-menu {
            margin: 0;
            padding: 0 0 25px;
            right: 0;
            min-width: 0;
            font-size: 0;
            text-align: left;
            @media (max-width: @screen-sm-max) {
              right: auto;
            }
            @media (max-width: @screen-xs-max) {
              text-align: center;
            }
            > li {
              display: inline-block;
              vertical-align: top;
              padding: 55px 25px 25px 55px;
              width: 25%;
              @media (max-width: @screen-sm-max) {
                width: 33.33333333%;
              }
              @media (max-width: @screen-xs-max) {
                padding: 10px 0;
                width: 100%;
              }
              > ul.dropdown-menu {
                text-align: left;
                @media (max-width: @screen-xs-max) {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  .secNav {
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 0;
    width: 100%;
    .transition(all 0.2s);
    @media (max-width: @screen-sm-max) {
      position: relative;
      top: auto;
      left: auto;
      margin-top: 60px;
    }
    > ul {
      float: right;
      margin: 0;
      padding: 0;
      list-style: none;
      @media (max-width: @screen-sm-max) {
        float: none;
      }
      > li {
        float: left;
        @media (max-width: @screen-sm-max) {
          float: none;
        }
        > a,
        > .nolink {
          display: block;
          margin: 0;
          padding: 6px 20px;
          background-color: @brand-primary;
          color: #fff;
          font-size: 0.94rem;
          font-weight: 500;
          line-height: 1.87em;
          text-transform: uppercase;
          .transition(all 0.2s);
          @media (max-width: @screen-sm-max) {
            padding: 15px;
            background-color: transparent !important;
            color: #000;
          }
        }
        &:hover,
        &.active,
        &.active-trail {
          > a,
          > .nolink {
            background-color: @brand-secondary;
            @media (max-width: @screen-sm-max) {
              color: #fff;
            }
          }
        }
        &:nth-child(odd) {
          > a,
          > .nolink {
            background-color: fade(@brand-primary, 90%);
          }
          &:hover,
          &.active,
          &.active-trail {
            > a,
            > .nolink {
              background-color: fade(@brand-secondary, 90%);
            }
          }
        }
      }
    }
  }
  .search {
    float: left;
    display: block;
    margin: 30px 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: @brand-primary;
    color: #fff;
    font-size: 0.88rem;
    line-height: 40px;
    text-align: center;
    .transition(all 0.2s);
    @media (max-width: @screen-md-max) {
      margin-left: 15px;
      margin-right: 0;
    }
    @media (max-width: @screen-sm-max) {
      margin-top: 35px;
      margin-bottom: 0;
      border-radius: 0;
    }
    @media (max-width: @screen-xs-max) {
      margin-top: 0;
    }
    &:hover {
      background-color: @brand-secondary;
    }
  }
  #searchBox {
    display: none;
    position: absolute;
    z-index: 0;
    top: 100%;
    left: 0;
    width: 100%;
    .container {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: @brand-secondary;
    }
    form {
      .clearfix;
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
    }
    .form-control,
    .input-group-btn {
      float: left;
    }
    .form-control {
      width: calc(~"100% - 50px");
    }
    .btn {
      padding: 0;
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
  &.scrolled {
    top: 0;
    .logo {
      padding-top: 14px;
      padding-bottom: 14px;
      @media (max-width: @screen-md-max) {
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }
    .mainNav {
      > ul {
        > li {
          padding-top: 20px;
          padding-bottom: 20px;
          &.open {
            @media (min-width: @screen-md-min) {
              padding-bottom: 18px;
            }
          }
        }
      }
      .search {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    .secNav {
      -webkit-transform: translate(0, -40px);
      -moz-transform: translate(0, -40px);
      -ms-transform: translate(0, -40px);
      -o-transform: translate(0, -40px);
      transform: translate(0, -40px);
    }
    @media (max-width: @screen-sm-max) {
      .top {
        .container {
          background-color: @brand-secondary;
        }
      }
      .search,
      .trigger-menu {
        margin-top: 0;
      }
    }
  }
}
body.front {
  #header {
    top: 60px;
    @media (max-width: @screen-sm-max) {
      top: 0;
    }
    &.scrolled {
      top: 0;
    }
  }
}

.trigger-menu {
  position: relative;
  z-index: 1;
  float: left;
  margin: 35px 0 0;
  margin-right: -15px;
  padding: 12px 10px;
  background-color: @brand-secondary;
  .transition(all 0.2s);
  @media (max-width: @screen-xs-max) {
    margin-top: 0;
  }
  svg {
    display: block;
    fill: #fff !important;
    rect {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -ms-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
      .transition(all 0.2s);
    }
  }
  &.open {
    svg {
      rect {
        fill: #fff !important;
        &:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          x: -3px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          -webkit-transform: rotate-(45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          x: -3px;
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  nav#nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 60px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: fade(@brand-primary, 90%);
    overflow-x: auto;
    overflow-y: scroll;
    .transition(all 0.35s);
  }
}

body.open-menu {
  @media (max-width: @screen-sm-max) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}

#banner,
.banner {
  position: relative;
  height: 870px;
  background-color: #000;
  @media (max-width: @screen-xs-max) {
    height: 360px;
  }
  .title {
    z-index: 10;
    width: 100%;
    h1 {
      span {
        display: block;
      }
    }
  }
  .subtitle {
    margin: 10px 0 0;
    color: @brand-primary;
    font-size: 1.31rem;
    line-height: normal;
    text-transform: none;
    font-weight: 400;
  }
  &:not(.banner-img) {
    .title {
      @media (min-width: @screen-sm-min) {
        .v-align;
        text-align: center;
      }
      @media (max-width: @screen-xs-max) {
        position: absolute;
        bottom: 15px;
        left: 0;
      }
    }
  }
  &.banner-img {
    height: 540px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: @screen-xs-max) {
      display: flex;
      align-items: flex-end;
      height: auto;
      padding-top: 125px;
      padding-bottom: 15px;
      min-height: 360px;
    }
    .title {
      position: absolute;
      bottom: 90px;
      left: 0;
      @media (max-width: @screen-xs-max) {
        position: static;
        bottom: auto;
        left: auto;
      }
    }
  }
  /* &.banner-01 {
        background-image: url(../img/banner-01.jpg);
    } */
  .bg {
    opacity: 0.5;
  }
  &.banner-product {
    height: 390px;
  }
}

// --------------------------------------------------------------------------------------------------------------------------------------------
// Inscription
// --------------------------------------------------------------------------------------------------------------------------------------------
#inscription {
  position: fixed;
  z-index: 30;
  top: 260px;
  right: -70px;
  width: 300px;
  color: #fff;

  h2 {
    margin: 0;
    background-color: @brand-primary;
    border-bottom: 1px solid lighten(@brand-primary, 10%);
    padding: 20px;
    color: #fff;
    font-size: 1.13rem;
    font-weight: 400;
    text-transform: uppercase;
    transition: 0.3s ease-out;
  }

  ul {
    display: none;
    margin: 0;
    padding: 30px 40px;
    background-color: darken(@brand-primary, 10%);
    li {
      display: block;
      margin: 0;
      border-top: 1px solid lighten(@brand-primary, 10%);
      padding: 5px 0;
      list-style: none;
      &:first-child {
        border-top: 0;
      }
      a {
        color: #fff;
      }
    }
  }
}
