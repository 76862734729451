// Home
#leaderboard {
	position: relative;
	height: 100vh;
	min-height: 950px;
	@media (max-width: @screen-sm-max) {
		height: 800px;
		min-height: 0;
	}
	@media(max-width: @screen-xs-max) {
		height: 600px;
	}
	&:before {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #000;
		opacity: .4;
	}
	.content {
		z-index: 5;
		width: 100%;
	}
	h1 {
		margin-bottom: 35px;
	}
	.btn {
		display: block;
		float: left;
		@media(max-width: @screen-xs-max) {
			float: none;
		}
		& + .btn {
			margin-left: 10px;
			@media(max-width: @screen-xs-max) {
				margin-top: 10px;
				margin-left: 0;
			}
		}
		
	}

	.swiper-container {
		height: 100%;

		.swiper-wrapper {
			height: 100%;
			
			.swiper-slide {
				background-size: cover;
				background-repeat: no-repeat;
			}
		}
	}
}

#intro {
	position: relative;
	padding-top: 105px;
	padding-bottom: 180px;
	@media(max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.content {
		position: relative;
		z-index: 5;
	}
	.title {
		font-size: 0;
		text-align: right;
		&:before {
			content: '';
			display: inline-block;
			height: 3px;
			width: 50px;
			background-color: #dfdfdf;
		}
		h2 {
			margin-top: 30px;
			float: right;
			@media(max-width: @screen-xs-max) {
				font-size: 1.88rem;
			}
		}
	}
	.body {
		padding-top: 35px;
		padding-bottom: 45px;
		font-size: 1.06rem;
		font-weight: 100;
		letter-spacing: -.2px;
		line-height: 1.88em;
		strong {
			font-weight: 400;
		}
		p, ol {
			margin: 0 0 35px;
		}
		ul {
			.custom-list;
			margin: 0 0 50px;
			li {
				& + li {
					margin-top: 15px;
				}
			}
		}
		.btn {
			.lined--primary;
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
	.photo {
		top: 150px;
		bottom: 180px;
		left: 58.33333333%;
		@media(max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 56.25%;
		}
	}
}

#offres {
	background-color: #f3f3f3;
	.title {
		.clearfix;
		padding: 50px 60px;
		background-color: #fff;
		@media(max-width: 1439px) {
			padding: 35px 45px;
		}
		@media(max-width: @screen-md-max) {
			padding-left: 30px;
			padding-right: 30px;
		}
		h2 {
			float: left;
			margin: 0;
			@media(max-width: 1439px) {
				float: none;
			}
			@media(max-width: @screen-xs-max) {
				font-size: 1.88rem;
			}
		}
		.more {
			margin-top: 14px;
			float: right;
			@media(max-width: 1439px) {
				float: none;
			}
		}
	}
	.wrapper {
		padding: 110px 130px;
		@media(max-width: 1439px) {
			padding-left: 45px;
			padding-right: 45px;
		}
		@media(max-width: @screen-md-max) {
			padding: 60px 30px;
		}
	}
	.solutions, .produits {
		border-top: 1px solid #e5e5e5;
	}
	.solutions {
		color: #000;
		font-size: 1.13rem;
		.title {
			padding: 50px 90px;
			@media(max-width: 1439px) {
				padding-left: 45px;
				padding-right: 45px;
			}
			@media(max-width: @screen-md-max) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
		ul {
			.custom-list;
			margin: 0 0 45px;
			@media(min-width: @screen-md-min) {
				-webkit-column-count: 2;
			    -moz-column-count: 2;
			    column-count: 2;
			    -webkit-column-gap: 10px;
			    -moz-column-gap: 10px;
			    column-gap: 10px;
			}
			li {
				display: inline-block;
			    margin: 0 0 10px;
			    width: 100%;
				&:before {
					font-size: 1.25rem;
				}
			}
		}
		a:not(.btn) {
			color: #232323;
			font-family: @ff-title;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.produits {
		.owl-prev, .owl-next {
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -32px;
			height: 65px;
			width: 65px;
			background-color: transparent;
			border: 1px solid #fff;
			border-radius: 50%;
			color: #fff;
			font-size: 1.31rem;
			line-height: 65px;
			text-align: center;
			opacity: .6;
			.transition(all .2s);
			&:hover {
				background-color: #fff;
				color: #29282e;
			}
		}
		.owl-prev {
			left: 25px;
			@media(max-width: @screen-md-max) {
				left: 15px;
			}
		}
		.owl-next {
			right: 25px;
			@media(max-width: @screen-md-max) {
				right: 15px;
			}
		}
	}
}

#raisons {
	padding-top: 55px;
	padding-bottom: 70px;
	background-color: @brand-primary;
	@media(max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	h2 {
		margin-top: 0;
		margin-bottom: 0;
		color: #fff;
		font-size: 2.25rem;
		line-height: 1.03em;
		text-align: center;
		@media(max-width: @screen-xs-max) {
			font-size: 1.88rem;
		}
	}
	.raisons {
		.clearfix;
		counter-reset: counter;
	}
	.raison {
		display: block;
		float: left;
		padding: 20px;
		width: 20%;
		color: #fff;
		font-size: 0.88rem;
		line-height: 1.5em;
		counter-increment: counter;
		@media(max-width: @screen-sm-max) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@media(max-width: @screen-xs-max) {
			width: 100%;
		}
		&:before {
			content: counter(counter);
			display: block;
			font-size: 1.88rem;
			line-height: 1em;
		}
	}
}

#secteurs {
	position: relative;
	background-color: #f5f5f5;
	.inner-wrapper {
		@media(min-width: @screen-sm-min) {
			-webkit-transform: translate(0,120px);
			-moz-transform: translate(0,120px);
			-ms-transform: translate(0,120px);
			-o-transform: translate(0,120px);
			transform: translate(0,120px);
		}
		@media(max-width: @screen-xs-max) {
			background-color: #fff;
		}
	}
	.content {
		position: relative;
		z-index: 5;
		.wrapper {
			.clearfix;
			padding: 65px 95px 80px;
			background-color: #fff;
			box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
			@media(max-width: @screen-md-max) {
				padding-left: 60px;
				padding-right: 60px;
			}
			@media(max-width: @screen-sm-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@media(max-width: @screen-xs-max) {
				padding: 60px 0;
				box-shadow: none;
			}
		}
		h2 {
			margin-top: 0;
			margin-bottom: 30px;
			color: @brand-primary;
			@media(max-width: @screen-xs-max) {
				font-size: 1.88rem;
			}
		}
		a {
			display: block;
			float: left;
			padding: 55px 25px;
			width: 50%;
			border-top: 1px solid rgba(0,0,0,.08);
			border-right: 1px solid rgba(0,0,0,.08);
			color: #222;
			font-family: @ff-title;
			font-size: 1.13rem;
			font-weight: 400;
			line-height: 1.5em;
			text-decoration: underline;
			.transition(all .2s);
			@media(max-width: @screen-sm-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@media(max-width: @screen-xs-max) {
				border: 0 !important;
				width: 100%;
				& + a {
					border-top: 1px solid rgba(0,0,0,.08) !important;
				}
			}
			&:nth-of-type(even) {
				border-right: 0;
			}
			&:nth-of-type(-n+2) {
				border-top: 0;
			}
			&:nth-last-of-type(2):nth-of-type(even) {
				border-bottom: 1px solid rgba(0,0,0,.08);
			}
			&:hover {
				background-color: #222;
				color: #fff;
			}
		}
	}

	.photo {
		right: 60%;
		@media(max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 56.25%;
		}
	}
}

#actualites {
	padding-top: 240px;
	padding-bottom: 100px;
	background-color: #29282e;
	@media(max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	h2 {
		margin-bottom: 40px;
		color: #fff;
		text-align: center;
		@media(max-width: @screen-xs-max) {
			font-size: 1.88rem;
		}
	}
	.owl-nav {
		@media(max-width: 1439px) {
			font-size: 0;
		}
	}
	.owl-prev, .owl-next {
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -32px;
		height: 65px;
		width: 65px;
		background-color: transparent;
		border: 1px solid #fff;
		border-radius: 50%;
		color: #fff;
		font-size: 1.31rem;
		line-height: 65px;
		text-align: center;
		opacity: .6;
		.transition(all .2s);
		@media(max-width: 1439px) {
			position: relative;
			display: inline-block;
			top: auto;
			left: auto !important;
			right: auto !important;
			margin-top: 32px;
		}
		&:hover {
			background-color: #fff;
			color: #29282e;
		}
	}
	.owl-prev {
		left: -300px;
		@media(max-width: 1800px) {
			left: -80px;
		}
	}
	.owl-next {
		right: -300px;
		@media(max-width: 1800px) {
			right: -80px;
		}
		@media(max-width: 1439px) {
			margin-left: 15px;
		}
	}
}

#clients {
	padding-top: 75px;
	padding-bottom: 90px;
	text-align: center;
	@media(max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	h2 {
		margin-bottom: 75px;
		@media(max-width: @screen-xs-max) {
			font-size: 1.88rem;
		}
	}
	.btn {
		margin-top: 60px;
		padding: 14px 40px;
		font-size: 1rem;
		font-weight: 700;
		line-height: 1.75em;
		&:before {
			left: calc(~"100% - 30px");
		}
	}
	.owl-nav {
		@media(max-width: 1439px) {
			font-size: 0;
			text-align: center;
		}
	}
	.owl-prev, .owl-next {
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		height: 40px;
		width: 40px;
		background-color: #ebebeb;
		color: @brand-primary;
		font-size: 1rem;
		line-height: 40px;
		text-align: center;
		.transition(all .2s);
		@media(max-width: 1439px) {
			position: relative;
			display: inline-block;
			top: auto;
			left: auto !important;
			right: auto !important;
			margin-top: 20px;
		}
		&:hover {
			background-color: @brand-primary;
			color: #fff;
		}
	}
	.owl-prev {
		left: -100px;
	}
	.owl-next {
		right: -100px;
		@media(max-width: 1439px) {
			margin-left: 15px;
		}
	}
}